<template>
  <div>
    <p
      class="mb-0 font-weight-bolder"
      style="padding: 0.2rem 0.5rem;"
    >
      {{ value.usuarioNome }}
    </p>
    <b-form-textarea
      v-model="observacao"
      style="height: 3.3rem;"
      :disabled="disableTextbox"
      @change="alterarValor"
    />

    <b-row
      class="d-flex justify-content-between"
      style="padding: 0rem 0.5rem;"
    >
      <b-col>
        <b-row>
          <b-col
            v-if="disableTextbox && editavel"
            cols="2"
          >
            <b-btn
              variant="none"
              class="btn-none m-0 p-0"
              style="text-decoration: underline;"
              @click="editar()"
            >
              <small>Editar</small>
            </b-btn>
          </b-col>
          <b-col
            v-if="disableTextbox && editavel"
            cols="2"
          >
            <b-btn
              variant="none"
              class="btn-none m-0 p-0"
              style="text-decoration: underline;"
              @click="remover(value)"
            >
              <small>Remover</small>
            </b-btn>
          </b-col>
          <b-col
            v-if="!disableTextbox && editavel"
            cols="2"
          >
            <b-btn
              variant="none"
              class="btn-none m-0 p-0"
              style="text-decoration: underline;"
              @click="update(value)"
            >
              <small>Salvar</small>
            </b-btn>
          </b-col>
          <b-col
            v-if="!disableTextbox && editavel"
            cols="2"
          >
            <b-btn
              variant="none"
              class="btn-none m-0 p-0"
              style="text-decoration: underline;"
              @click="cancelar(value)"
            >
              <small>Cancelar</small>
            </b-btn>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="3"
        style="text-align: right;"
      >
        <p><small>{{ value.dataHora }}</small></p>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'VisualizarObservacao',
  props: {
    value: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      disableTextbox: true,
      originalValue: '',
      observacao: '',
    }
  },
  created() {
    this.observacao = this.value.observacao
  },
  methods: {
    alterarValor(valor) {
      this.value.observacao = valor
    },
    editar() {
      this.originalValue = this.value.observacao
      this.disableTextbox = false
    },
    update(valor) {
      if (valor.observacao.length > 0) {
        this.disableTextbox = true
        this.value = valor
        this.$emit('update', this.value)
      }
    },
    cancelar() {
      this.disableTextbox = true
      this.observacao = this.originalValue
    },
    remover(value) {
      this.$emit('delete', value)
    },
  },
}
</script>
